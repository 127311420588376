import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "listsPanel", "checkbox", "arrow"];

  // Add event listener to close dropdown when clicking outside
  connect() {
    document.addEventListener("click", this.closeDropdown.bind(this));
    this.element
      .querySelector("input[value='all']")
      .addEventListener("change", this.selectAll.bind(this));
    this.updateSelectedValues();
  }

  // Remove event listener when controller is disconnected
  disconnect() {
    document.removeEventListener("click", this.closeDropdown.bind(this));
  }

  // Toggles the visibility of the dropdown options when clicked
  toggleListPanel(event) {
    this.listsPanelTarget.classList.toggle("hidden");
    this.arrowTarget.classList.toggle("arrow--rotated");
  }

  // Updates the selected values when a checkbox is clicked
  updateSelectedValues() {
    const selectedValues = [];

    // Collect all checked checkboxes
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedValues.push(checkbox.value);
      }
    });

    // Update the text to show selected values
    this.textTarget.innerHTML =
      selectedValues.length > 0 ? selectedValues.join(", ") : "undefined";
  }

  selectAll(e) {
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.value !== 'all') {        
        checkbox.checked = false
        checkbox.disabled = !checkbox.disabled
      }
    });
    
    this.textTarget.innerHTML = e.target.checked ? "all" : "undefined";
  }

  // Close the dropdown if clicking outside
  closeDropdown(event) {
    if (!this.element.contains(event.target)) {
      this.listsPanelTarget.classList.add("hidden");
      this.arrowTarget.classList.remove("arrow--rotated");
    }
  }
}
