import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["numberInput"];

  connect() {
    this.numberInputTarget.addEventListener(
      "keypress",
      this.handleKeyPress.bind(this)
    );
  }

  handleKeyPress(event) {
    const isNonNumeric = !/^[0-9]$/.test(event.key);

    if (isNonNumeric) {
      event.preventDefault();
    }
  }
}
