import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterModal", "content", "form"];

  connect() {
    this.filterModalTarget.addEventListener("click", (event) =>
      this.handleOutsideClick(event)
    );
  }

  disconnect() {
    this.filterModalTarget.removeEventListener(
      "click",
      this.handleOutsideClick
    );
  }

  handleOutsideClick(event) {
    if (!this.contentTarget.contains(event.target)) {
      this.close();
    }
  }

  close() {
    this.filterModalTarget.classList.remove("modal--show");
  }

  open() {
    this.filterModalTarget.classList.add("modal--show");
  }

  clearFields() {
    const inputs = this.contentTarget.querySelectorAll("input[type='search']");
    inputs.forEach((input) => {
      input.value = "";
    });
    this.formTarget.submit();
    this.close();
  }
}
